import { useContext } from 'react';

import type { QueryKey } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { updateInvitationPermission } from '@/services/api/workspace/resources';
import { WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { TResourceSearchResult } from '@/types/timeline';

import { SEARCH_MANAGER_RESOURCES_KEY } from './useSearchManagerResourcesQuery';

type ResourceInvitationPermissionUpdate = {
  workspaceId: string;
  permission: WORKSPACE_MEMBER_PERMISSION;
  resourceId: string;
};

export default function useUpdateResourcePermission({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
} = {}) {
  const queryClient = useQueryClient();

  const { addNotification } = useContext(NotificationsContext);
  return useMutation<
    void,
    Error & { message?: string },
    ResourceInvitationPermissionUpdate,
    [QueryKey, TResourceSearchResult[] | undefined][]
  >(
    async ({
      workspaceId,
      permission,
      resourceId,
    }: ResourceInvitationPermissionUpdate) => {
      await updateInvitationPermission({
        permission,
        workspaceId,
        resourceId
      });
    },
    {
      // onMutate: async ({
      //   resourceId,
      //   permission,
      //   workspaceId,
      //   invitationId,
      // }) => {
      //   const key = [SEARCH_RESOURCES_KEY, workspaceId];
      //   const oldDatas =
      //     queryClient.getQueriesData<TResourceSearchResult[]>(key);

      //   oldDatas.forEach(([queryKey, oldData]) => {
      //     if (!oldData) return;
      //     const newData = cloneDeep(oldData);
      //     const oldAccount = newData.find(
      //       (nd) =>
      //         nd.invitation &&
      //         nd.invitation.id === invitationId &&
      //         nd.id === resourceId,
      //     );
      //     if (!oldAccount) return;
      //     oldAccount.permission = permission;
      //     queryClient.setQueryData(queryKey, newData);
      //   });

      //   return oldDatas;
      // },
      onSuccess: async (_data) => {
        queryClient.invalidateQueries([SEARCH_MANAGER_RESOURCES_KEY]);
        onSuccess?.();
      },
      onError: (error, _variables, oldDatas) => {
        oldDatas?.forEach(([queryKey, oldData]) => {
          queryClient.setQueryData(queryKey, oldData);
        });
        onError?.(error);
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
    },
  );
}
