import { useQuery } from '@tanstack/react-query';

import { http } from '@/services/api';
import { normalizePaddleProduct } from '@/services/normalizer';
import { BaseResponse } from '@/types/base-responses';
import { TProduct } from '@/types/paddle';

const GET_PRODUCTS_KEY = 'get-products-by-id';
export default function useGetProductById({
  enabled,
  productId,
}: {
  enabled: boolean;
  productId: string;
}) {
  const KEY = [GET_PRODUCTS_KEY, productId];
  return useQuery(
    KEY,
    async ({ queryKey: [_, prId] }) => {
      if (!prId) throw new Error('Product ID is required');
      const { data } = await http.get<BaseResponse<TProduct>>(
        `/products/${prId}`,
      );
      return normalizePaddleProduct(data.data);
    },
    { enabled },
  );
}
