import {
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import Button from '@components/Button';
import Input from '@components/Input';
import { t } from 'i18next';
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook';

import { UserContext } from '@/contexts/UserContext';
import { ANALYTICS_EVENTS, useAnalytics } from '@/hooks/utils/useAnalytics';
import useAddNewProject from '@/hooks/workspace/projects/useAddNewProject';
import { titleCase } from '@/services/helpers';
import { PROJECT_COLOR, PROJECT_STATUS } from '@/types/enums';
import { TResourceSearchResult } from '@/types/timeline';

import { INDICATOR_MAPPING } from '@/components/Indicators';
import InputSelect from '@/components/InputSelect';
import Modal from '@/components/Modals/Modal';

import SelectResource from './SelectResource';
import styles from './styles.module.css';
import ColorSelector from '../../ColorSelector';
import EmojiSelector from '../../EmojiSelector';

const MODAL_SCOPE = 'ModalAddNewProject-scope';
type Props = {
  isOpen: boolean;
  resourceId?: string;
  canAddPerson?: boolean;
  onClose: () => void;
};
export default function ModalAddNewProject({
  isOpen,
  onClose,
  resourceId,
  canAddPerson = false,
}: Props) {
  const { mutate, isLoading } = useAddNewProject();
  const [name, setName] = useState<string>('');
  const [emoji, setEmoji] = useState<string | undefined>(undefined);
  const [color, setColor] = useState<PROJECT_COLOR>(PROJECT_COLOR.GREY);
  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const [selectedResource, setSelectedResource] = useState<
    TResourceSearchResult | undefined
  >(undefined);
  const { disableScope, enableScope } = useHotkeysContext();
  const [status, setStatus] = useState<PROJECT_STATUS>(PROJECT_STATUS.TO_DO);

  useEffect(() => {
    if (isOpen) enableScope(MODAL_SCOPE);
    else disableScope(MODAL_SCOPE);
    return () => disableScope(MODAL_SCOPE);
  }, [disableScope, enableScope, isOpen]);

  const { workspaceId, workspace } = useContext(UserContext);
  const { trackEvent } = useAnalytics();

  const onSuccessFn = useCallback(() => {
    trackEvent(ANALYTICS_EVENTS.PROJECT_CREATED, workspaceId as string);
    onClose();
  }, [onClose, trackEvent, workspaceId]);

  const onSubmit = useCallback(
    (e?: SyntheticEvent) => {
      e?.preventDefault();
      mutate(
        {
          name,
          resourceId: resourceId ?? selectedResource?.id,
          color,
          emoji,
          status,
        },
        { onSuccess: onSuccessFn },
      );
    },
    [
      color,
      emoji,
      mutate,
      name,
      onSuccessFn,
      resourceId,
      selectedResource?.id,
      status,
    ],
  );

  useHotkeys('enter', () => name && !isLoading && onSubmit(), {
    scopes: MODAL_SCOPE,
    preventDefault: true,
    enableOnFormTags: true,
  });

  const onChangeFn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
  };

  useEffect(() => {
    setName('');
  }, [isOpen]);

  const mapStatusItem = useCallback((item: PROJECT_STATUS) => {
    const Indicator = INDICATOR_MAPPING[item];
    return (
      <div className={styles.statusItem}>
        <Indicator />
        <span>{titleCase(item.replace('_', ' '))}</span>
      </div>
    );
  }, []);

  const onSubmitFn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!name) {
      setNameError(
        t('forms:required', {
          field: t('common:modals.addNewProject.label'),
        }),
      );
      return;
    } else if (name.length > 180) {
      setNameError(
        t('forms:maxLength', { field: 'TProject name', count: 180 }),
      );
      return;
    }
    setNameError(undefined);
    onSubmit();
  };
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      const tm = setTimeout(() => ref?.current?.focus(), 100);
      return () => clearTimeout(tm);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      modalKey={`modal-add-new-project-${resourceId}`}
      onClose={onClose}
      title={t('common:modals.addNewProject.title')}
      footer={
        <>
          <Button
            label={t('common:modals.addNewProject.primaryCtaLabel')}
            onClick={onSubmitFn}
            size="medium"
            disabled={!name || isLoading}
            isLoading={isLoading}
          />
          <Button
            label={t('common:cancel')}
            onClick={onClose}
            size="medium"
            variant="ghost"
          />
        </>
      }
    >
      <form className={styles.inputWrapper} onSubmit={onSubmit}>
        <div className={styles.inputRow}>
          <div className={styles.inputMain}>
            <Input
              required
              ref={ref}
              className={styles.projectInput}
              label={t('common:modals.addNewProject.label') as string}
              value={name}
              placeholder={'E.g My project'}
              onChange={onChangeFn}
              name="name"
            />
          </div>
          <div className={styles.inputTrailing}>
            <EmojiSelector
              defaultValue={emoji}
              onSelect={(emj) => setEmoji(emj)}
            />
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.inputContent}>
            <InputSelect
              type="shortcut"
              label="Status"
              menuId="status-select"
              fullWidth
              options={Object.values(PROJECT_STATUS)}
              optionsMapper={mapStatusItem}
              selectionMapper={mapStatusItem}
              selectedElement={status}
              onSelectChange={(item) => setStatus(item)}
              shortcutMapper={(_, index) => `${index + 1}`}
            />
            <div className={styles.autoUpdateEnabled}>
              <span>
                {t('common:modals.editProject.automations.autoUpdate', {
                  value: workspace?.autoSync ? 'enabled' : 'disabled',
                })}
              </span>
            </div>
          </div>
        </div>
        <span
          style={{
            display: !nameError ? 'none' : 'block',
          }}
          className={styles.error}
        >
          {nameError}
        </span>
        <ColorSelector color={color} onChange={setColor} />
        {canAddPerson && (
          <SelectResource
            label={'Choose a person'}
            queryEnabled={canAddPerson}
            selectedResourceId={selectedResource?.id}
            onResourceSelected={setSelectedResource}
          />
        )}
      </form>
    </Modal>
  );
}
