import {
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { syncSubscriptionWithPaddle } from '@/services/api/workspace/workspace';

import { GET_SUBSCRIPTION_KEY } from './useGetSubscription';

export default function useSubscriptionSync() {
  const queryClient = useQueryClient();
  return useMutation(
    async (workspaceId: string) => {
      const data = await syncSubscriptionWithPaddle(workspaceId);
      return data;
    },
    {
      onSuccess: (data, workspaceId) => {
        console.log(data);
        queryClient.setQueryData([GET_SUBSCRIPTION_KEY, workspaceId], data);
      },
    },
  );
}
