import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { IconRefresh } from '@tabler/icons-react';
import { format, formatDistance } from 'date-fns';
import { enGB } from 'date-fns/locale';

import { useProfileModal } from '@/contexts/ModalPreferenceContext';
import { UserContext, useSubscription } from '@/contexts/UserContext';
import useSubscriptionSync from '@/hooks/workspace/useSubscriptionSync';

import Button from '@/components/Button';
import FreePlanCard from '@/components/PlanCard/FreePlanCard';
import ProPlanCard from '@/components/PlanCard/ProPlanCard';
import Section from '@/components/Section';
import UsageProgress from '@/components/UsageProgress';

import styles from './styles.module.css';

const useTimer = function ({
  enable,
  baseDate,
  date,
}: {
  enable: boolean;
  baseDate?: Date;
  date?: Date;
}) {
  const [compareDate, setCompareDate] = useState<Date>(baseDate ?? new Date());

  const lastSyncText = useMemo(() => {
    if (!date) return;
    if (date > new Date(compareDate.getTime() - 24 * 60 * 60 * 1000))
      return formatDistance(date, compareDate, {
        locale: enGB,
        addSuffix: true,
      });
    return format(date, 'MMM dd, yyyy HH:mm');
  }, [compareDate, date]);

  const overFiveMinutes = useMemo(() => {
    if (!date) return false;
    const fiveMinutesAgo = compareDate.getTime() - 5 * 60 * 1000;
    return date.getTime() < fiveMinutesAgo;
  }, [compareDate, date]);

  useEffect(() => {
    if (enable) {
      const secondsToMinute = 60 - new Date().getSeconds();
      const interval: NodeJS.Timeout = setInterval(() => {
        setCompareDate(new Date());
        console.log('interval');
      }, secondsToMinute * 1000);
      return () => clearInterval(interval);
    }
  }, [date, enable]);
  return { lastSyncText, overFiveMinutes, compareDate };
};

export default function ModalPlans() {
  const { usageInfoData: { overflow } = { overflow: false }, workspace } =
    useContext(UserContext);

  const { subscription } = useSubscription();

  const { closeModal } = useProfileModal();

  const { mutate: syncSubscription, isLoading } = useSubscriptionSync();

  const updateSubscriptionSync = useCallback(() => {
    if (workspace?.id) syncSubscription(workspace?.id);
  }, [syncSubscription, workspace?.id]);

  const { overFiveMinutes } = useTimer({
    enable: subscription?.lastUpdatedAt !== undefined,
    baseDate: new Date(),
    date: subscription?.lastUpdatedAt,
  });

  return (
    <Section>
      <UsageProgress />
      <div className={styles.container}>
        <p className={styles.planCardDescription}>
          {overflow
            ? "You've reached your plan limits. Upgrade to unlock more active projects."
            : !subscription
              ? 'Upgrade to unlock more active projects.'
              : undefined}
        </p>
        <div className={styles.usageCardsContainer}>
          <FreePlanCard />
          <ProPlanCard onCheckoutComplete={closeModal} />
        </div>

        {overFiveMinutes && (
          <span className={styles.syncButtonContainer}>
            <Button
              size="small"
              onClick={updateSubscriptionSync}
              icon={IconRefresh}
              iconProps={{ width: 16, style: { marginRight: '2px' } }}
              label="Restore purchase"
              isLoading={isLoading}
              variant="link"
              disabled={isLoading || !overFiveMinutes}
              className={styles.syncButton}
            />
          </span>
        )}
      </div>
    </Section>
  );
}
