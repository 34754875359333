import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { noop } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { getMemberCountByStatus } from '@/services/api/workspace/resources';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';

export const GET_MEMBERS_COUNT_BY_STATUS_QUERY = 'get-members-count-by-status';
export default function useResourceCountByStatusQuery({
  query,
  enabled,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<{
  resourceActiveCount: number;
  resourceHiddenCount: number;
}> & {
  query?: string;
}) {
  const { workspaceId } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);

  return useQuery(
    [GET_MEMBERS_COUNT_BY_STATUS_QUERY, workspaceId, query] as [
      string,
      string,
      string | undefined,
    ],
    ({ queryKey: [_k, ws, q] }) => {
      if (ws) {
        return getMemberCountByStatus({ q, workspaceId: ws });
      }
    },
    {
      enabled: enabled && !!workspaceId,
      onError: (error: TResponseError) => {
        onError(error);
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
      onSuccess,
    },
  );
}
