import { omit } from 'lodash';

import {
  normalizeInfiniteQuery,
  normalizeResources,
  normalizeResourceSearchResult,
  normalizeTimelineResourceProject,
  normalizeTimelineResources,
} from '@/services/normalizer';
import { BaseListResponse, BaseResponse } from '@/types/base-responses';
import { WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { Dto, TPagedQuery, TResponse } from '@/types/generic';
import {
  TAllocationEvent,
  TResourceItemList,
  TResources,
  TTimelineAllocationResponse,
  TTimelineResource,
} from '@/types/timeline';
import { TWorkspaceAccess } from '@/types/workspace';

import { http } from '..';

/**
 * This function searches for resources within a specific workspace and project using a provided query string.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.query - The query string to use for the search.
 * @param {string} params.projectId - The ID of the project within which to search for resources.
 * @param {string | null} params.workspaceId - The ID of the workspace within which to search for resources. If null, the function will not proceed.
 *
 * @returns {Promise<TResourceItemList[]> | undefined} - A promise that resolves to an array of resource items that match the query, or undefined if an error occurs.
 *
 * @throws Will throw an error if the request fails.
 */
export async function searchResources({
  query,
  workspaceId,
  projectId,
  limit = 8,
}: {
  query?: string;
  projectId?: string;
  workspaceId?: string | null;
  limit?: number;
  page?: number;
}) {
  const {
    data: { data },
  }: { data: TResponse<Dto<TResourceItemList[]>> } = await http.get(
    `/workspaces/${workspaceId}/resources/search?limit=${limit}&q=${query ?? ''}${projectId ? `&projectId=${projectId}` : ''}`,
  );
  return data.map(normalizeResourceSearchResult);
}

export async function searchManagerResources({
  query,
  workspaceId,
  projectId,
  showHidden = false,
  pageSize = 8,
  page,
}: {
  query?: string;
  projectId?: string;
  workspaceId?: string | null;
  showHidden?: boolean;
  pageSize?: number;
  page?: number;
}) {
  const { data } = await http.get<
    BaseListResponse<Dto<TResourceItemList>> & { hiddenResourceCount: number }
  >(
    `/workspaces/${workspaceId}/resources/manager/list?showHidden=${showHidden}&page=${page ?? 1}&pageSize=${pageSize}&q=${query ?? ''}${projectId ? `&projectId=${projectId}` : ''}`,
  );
  return {
    ...normalizeInfiniteQuery(
      omit(data, 'data') as unknown as Omit<TPagedQuery<unknown>, 'results'>,
    ),
    hiddenResourceCount: data.hiddenResourceCount,
    results: data.data?.map(normalizeResourceSearchResult) ?? [],
  };
}

/**
 * This function retrieves timeline resources from a specific workspace within a given date range.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace from which to retrieve the timeline resources. If null, the function will not proceed.
 * @param {number | null} params.startDate - The start date of the range within which to retrieve the timeline resources, represented as a Unix timestamp. If null, the function will retrieve resources from the beginning of the timeline.
 * @param {number | null} params.endDate - The end date of the range within which to retrieve the timeline resources, represented as a Unix timestamp. If null, the function will retrieve resources up to the current date.
 *
 * @returns {Promise<TTimelineResource[]> | undefined} - A promise that resolves to an array of timeline resources, or undefined if an error occurs.
 *
 * @throws Will throw an error if the request fails.
 */

export async function getTimelineResources({
  workspaceId,
  startDate,
  endDate,
}: {
  workspaceId?: string | null;
  startDate: number | null;
  endDate: number | null;
}) {
  const {
    data: { data },
  }: { data: TResponse<Dto<TTimelineResource[]>> } = await http.get(
    `/workspaces/${workspaceId}/timeline`,
    {
      params: {
        startDate,
        endDate,
      },
    },
  );
  return data?.map(normalizeTimelineResources);
}

/**
 * This asynchronous function retrieves resources from a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace from which to retrieve resources. If null, the function will not proceed.
 *
 * @returns {Promise<Array<TResources & { hidden: { totalCount: number } }>> | undefined} - A promise that resolves to an array of resources, each with an additional property 'hidden' that contains the total count of hidden resources. Returns undefined if an error occurs.
 *
 * @throws Will throw an error if the request fails.
 */

export async function getResources({
  workspaceId,
}: {
  workspaceId?: string | null;
}) {
  const {
    data: { data },
  }: {
    data: TResponse<
      Dto<Array<TResources & { hidden: { totalCount: number } }>>
    >;
  } = await http.get(`/workspaces/${workspaceId}/resources`);
  return data?.map(normalizeResources);
}

/**
 * This asynchronous function adds a new resource to a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace to which the resource will be added. If null, the function will not proceed.
 * @param {string} params.firstName - The first name of the resource.
 * @param {string} params.lastName - The last name of the resource.
 * @param {number} params.capacity - The capacity of the resource.
 * @param {Object} params.image - An object containing the file path of the resource's image.
 * @param {TWorkspaceAccess} params.workspaceAccess - An object representing the resource's access to the workspace.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function addNewResource({
  workspaceId,
  firstName,
  lastName,
  capacity,
  image,
  workspaceAccess,
}: {
  workspaceId?: string | null;
  firstName: string;
  lastName: string;
  capacity: number;
  workspaceAccess: TWorkspaceAccess;
  image: {
    filePath: string;
  };
}) {
  return http.post(`/workspaces/${workspaceId}/resources`, {
    firstName,
    lastName,
    capacity: capacity,
    image,
    workspaceAccess: workspaceAccess?.hasAccess ? workspaceAccess : null,
  });
}

/**
 * This asynchronous function updates a specific resource in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the resource is located. If null, the function will not proceed.
 * @param {string} params.resourceId - The ID of the resource to be updated.
 * @param {string} params.firstName - The new first name of the resource.
 * @param {string} params.lastName - The new last name of the resource.
 * @param {number} params.capacity - The new capacity of the resource.
 * @param {TWorkspaceAccess} params.workspaceAccess - An object representing the resource's new access to the workspace.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function updateResource({
  workspaceId,
  resourceId,
  firstName,
  lastName,
  capacity,
  workspaceAccess,
}: {
  workspaceId?: string | null;
  resourceId: string;
  firstName: string;
  lastName: string;
  capacity: number;
  workspaceAccess?: TWorkspaceAccess;
}) {
  return http.put(`/workspaces/${workspaceId}/resources/${resourceId}`, {
    firstName,
    lastName,
    capacity,
    workspaceAccess,
  });
}

/**
 * This asynchronous function adds a new resource to a specific workspace and assigns it to a project.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.projectId - The ID of the project to which the resource will be assigned.
 * @param {string | null} params.workspaceId - The ID of the workspace to which the resource will be added. If null, the function will not proceed.
 * @param {string} params.firstName - The first name of the resource.
 * @param {string} params.lastName - The last name of the resource.
 * @param {number} params.capacity - The capacity of the resource.
 * @param {Object} params.image - An object containing the file path of the resource's image.
 * @param {TWorkspaceAccess} params.workspaceAccess - An object representing the resource's access to the workspace.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function addNewResourceAndAssignToProject({
  workspaceId,
  firstName,
  lastName,
  capacity,
  image,
  projectId,
  workspaceAccess,
}: {
  projectId: string;
  workspaceId?: string | null;
  firstName: string;
  lastName: string;
  capacity: number;
  workspaceAccess: TWorkspaceAccess;
  image: {
    filePath: string;
  };
}) {
  return http.post(`/workspaces/${workspaceId}/resources/createAndAssign`, {
    firstName,
    lastName,
    capacity,
    image,
    workspaceAccess: workspaceAccess?.hasAccess ? workspaceAccess : null,
    projectId,
  });
}

/**
 * This asynchronous function updates the image of a specific resource in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the resource is located. If null, the function will not proceed.
 * @param {string} params.resourceId - The ID of the resource whose image is to be updated.
 * @param {string} params.filePath - The file path of the new image.
 *
 * @returns {Promise<TResources & { hidden: { totalCount: number } } | undefined>} - A promise that resolves to a TResources object representing the updated resource, or undefined if an error occurs.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function updateResourceImage({
  workspaceId,
  resourceId,
  filePath,
}: {
  workspaceId?: string | null;
  resourceId: string;
  filePath: string;
}) {
  const {
    data: { data },
  }: {
    data: TResponse<Dto<TResources & { hidden: { totalCount: number } }>>;
  } = await http.put(
    `/workspaces/${workspaceId}/resources/${resourceId}/image`,
    {
      filePath,
    },
  );
  return normalizeResources(data);
}

/**
 * This asynchronous function assigns a project to a specific resource in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the resource is located. If null, the function will not proceed.
 * @param {string} params.resourceId - The ID of the resource to which the project will be assigned.
 * @param {string} params.projectId - The ID of the project to be assigned to the resource.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function assignProjectToResource({
  workspaceId,
  resourceId,
  projectId,
}: {
  workspaceId?: string | null;
  resourceId: string;
  projectId: string;
}) {
  return http.post(`/workspaces/${workspaceId}/resources/${resourceId}/join`, {
    projectId,
  });
}

/**
 * This asynchronous function unhidden a project assigned to a specific resource in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the resource is located. If null, the function will not proceed.
 * @param {string} params.resourceId - The ID of the resource to which the project is assigned.
 * @param {string} params.projectId - The ID of the project to be unhidden.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function unhideProjectToResource({
  workspaceId,
  resourceId,
  projectId,
}: {
  workspaceId?: string | null;
  resourceId: string;
  projectId: string;
}) {
  return http.put(
    `/workspaces/${workspaceId}/resources/${resourceId}/unhide/${projectId}`,
  );
}

/**
 * This asynchronous function removes a project from a specific resource in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the resource is located. If null, the function will not proceed.
 * @param {string} params.resourceId - The ID of the resource from which the project will be removed.
 * @param {string} params.projectId - The ID of the project to be removed from the resource.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function removeProjectToResource({
  workspaceId,
  resourceId,
  projectId,
}: {
  workspaceId?: string | null;
  resourceId: string;
  projectId: string;
}) {
  return http.delete(
    `/workspaces/${workspaceId}/resources/${resourceId}/leave/${projectId}`,
  );
}

/**
 * This asynchronous function deletes the image of a specific resource in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the resource is located. If null, the function will not proceed.
 * @param {string} params.resourceId - The ID of the resource whose image is to be deleted.
 * @param {string} params.imageId - The ID of the image to be deleted.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function deleteResourceImage({
  workspaceId,
  resourceId,
  imageId,
}: {
  workspaceId?: string | null;
  resourceId: string;
  imageId: string;
}) {
  return http.delete(
    `/workspaces/${workspaceId}/resources/${resourceId}/image/${imageId}`,
  );
}

/**
 * This asynchronous function updates the allocation of a specific resource to a project in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the resource and project are located. If null, the function will not proceed.
 * @param {string} params.resourceId - The ID of the resource whose allocation is to be updated.
 * @param {string} params.projectId - The ID of the project to which the resource is allocated.
 * @param {TAllocationEvent[]} params.events - An array of TAllocationEvent objects representing the allocation events to be updated.
 *
 * @returns {Promise<TTimelineAllocationResponse | undefined>} - A promise that resolves to a TTimelineAllocationResponse object representing the updated allocation, or undefined if an error occurs.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function updateAllocation({
  workspaceId,
  resourceId,
  projectId,
  events,
}: {
  workspaceId?: string | null;
  resourceId: string;
  projectId: string;
  events: TAllocationEvent[];
}) {
  const _events = events;

  const {
    data: { data },
  }: { data: TResponse<Dto<TTimelineAllocationResponse>> } = await http.put(
    `/workspaces/${workspaceId}/resources/${resourceId}/allocations`,
    {
      projectId,
      events: _events,
    },
  );
  return normalizeTimelineResourceProject(data);
}

/**
 * This asynchronous function deletes a specific resource in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the resource is located. If null, the function will not proceed.
 * @param {string} params.resourceId - The ID of the resource to be deleted.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function deleteResource({
  workspaceId,
  resourceId,
}: {
  workspaceId?: string | null;
  resourceId: string;
}) {
  return http.delete(`/workspaces/${workspaceId}/resources/${resourceId}`);
}

/**
 * This asynchronous function updates the order of projects assigned to a specific resource in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the resource is located. If null, the function will not proceed.
 * @param {string} params.resourceId - The ID of the resource whose projects order is to be updated.
 * @param {string[]} params.newOrder - The new order of the projects.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function updateProjectsOrder({
  workspaceId,
  resourceId,
  newOrder,
}: {
  workspaceId?: string | null;
  resourceId: string;
  newOrder: string[];
}) {
  return http.put(
    `/workspaces/${workspaceId}/resources/${resourceId}/changeProjectOrder`,
    newOrder,
  );
}

/**
 * This asynchronous function updates the order of resources in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the order of resources is to be updated. If null, the function will not proceed.
 * @param {string[]} params.newOrder - The new order of the resources.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function updateResourcesOrder({
  workspaceId,
  newOrder,
}: {
  workspaceId?: string | null;
  newOrder: string[];
}) {
  return http.put(`/workspaces/${workspaceId}/resources/changeOrder`, newOrder);
}

export async function updateInvitationPermission({
  workspaceId,
  resourceId,
  permission,
}: {
  workspaceId: string;
  permission: WORKSPACE_MEMBER_PERMISSION;
  resourceId: string;
}) {
  return http.put(
    `/workspaces/${workspaceId}/resources/${resourceId}/permission`,
    { permission },
  );
}

export async function hideMemeber({
  workspaceId,
  resourceId,
  revokeAccess = false,
}: {
  workspaceId: string;
  resourceId: string;
  revokeAccess?: boolean;
}) {
  return http.put(`/workspaces/${workspaceId}/resources/${resourceId}/hide`, {
    revokeAccess,
  });
}

export async function unhideMemeber({
  workspaceId,
  resourceId,
}: {
  workspaceId: string;
  resourceId: string;
}) {
  return http.put(`/workspaces/${workspaceId}/resources/${resourceId}/unhide`);
}

export async function getMemberCountByStatus({
  workspaceId,
  q,
}: {
  workspaceId: string;
  q?: string;
}) {
  const {
    data: { data },
  } = await http.get<
    BaseResponse<{
      resourceActiveCount: string | number;
      resourceHiddenCount: string | number;
    }>
  >(
    `/workspaces/${workspaceId}/resources/manager/list/status-count?q=${q ?? ''}`,
  );
  return data
    ? {
        resourceActiveCount: convertStringToNumber(data.resourceActiveCount, 0),
        resourceHiddenCount: convertStringToNumber(data.resourceHiddenCount, 0),
      }
    : {
        resourceActiveCount: 0,
        resourceHiddenCount: 0,
      };
}

function convertStringToNumber(vl: string | number, defaultValue?: number) {
  if (!vl) return defaultValue;
  else if (typeof vl === 'number') return vl;
  else if (typeof vl === 'string') return parseInt(vl);
  else return defaultValue;
}
