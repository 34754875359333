import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { FirebaseError } from '@firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
} from '@firebase/auth';
import classNames from 'classnames';
import { t } from 'i18next';
import { isNil, omit } from 'lodash';
import queryString from 'query-string';
import { useNavigate } from 'react-router';

import { UserContext } from '@/contexts/UserContext';
import useCreateAccount from '@/hooks/account/useCreateAccount';
import useInvitationQuery from '@/hooks/account/useInvitationQuery';
import { ANALYTICS_EVENTS, useAnalytics } from '@/hooks/utils/useAnalytics';
import { TResponseError } from '@/types/generic';
import { ROUTES } from '@/types/routes';

import InvitationExpiredError409 from '@/screens/ErrorScreen/400/InvitationlExpiredError400';

import BoxMessage from '@/components/BoxMessage';
import Button from '@/components/Button';
import SignInIllustration from '@/components/Illustrations/SignInIllustration';
import Layout from '@/components/Layout';
import Loader from '@/components/Loader';
import LogoHeader from '@/components/LogoHeader';
import SignUpForm, {
  SignUpFormData,
  SignUpFormRefType,
} from '@/components/SignUpForm.tsx';

import { Error500 } from '../ErrorScreen/500';
import stylesSignIn from '../SignIn/styles.module.css';

export default function SignUpScreen() {
  const auth = getAuth();

  const navigate = useNavigate();
  const { trackEvent, trackUserId, trackGroupId } = useAnalytics();

  const [error, setError] = useState('');
  const [errorElement, setErrorElement] = useState<JSX.Element | undefined>(
    undefined,
  );
  const { tk: token } = queryString.parse(location.search);
  const { isLoading: checkInvitationLoading, isFetching } = useInvitationQuery({
    enabled: !isNil(token),
    onError: (error: TResponseError) => {
      if (error.status === 400) {
        setErrorElement(<InvitationExpiredError409 />);
      } else {
        setErrorElement(<Error500 />);
      }
    },
  });

  const { isLoggedIn, workspace } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useCreateAccount({
    onSuccess: (data) => {
      setIsLoading(false);

      trackUserId({
        email: data?.email as string,
        name:
          data?.firstName && data.lastName
            ? `${data?.firstName} ${data?.lastName}`
            : '',
        avatar: undefined,
        weeklyEmail: workspace?.weeklyEmail,
        workspaceRole: workspace?.role,
        workspacePermission: workspace?.permission,
        onSuccess: () => {
          trackEvent(ANALYTICS_EVENTS.SIGNED_UP, workspace?.id as string);
          // navigate(ROUTES.EMAIL_VERIFICATION);
        },
      });
      trackGroupId({
        id: workspace?.id ?? '',
        groupName: workspace?.name ?? '',
        timeMode: workspace?.mode,
      });
    },
  });

  const formRef = useRef<SignUpFormRefType | null>(null);

  useEffect(() => {
    if (isLoggedIn) {
      if (auth.currentUser?.emailVerified) {
        navigate(ROUTES.ROOT, { replace: true });
      } else {
        navigate(ROUTES.EMAIL_VERIFICATION, { replace: true });
      }
    }
  }, [isLoggedIn, navigate, auth.currentUser?.emailVerified]);

  /*
    const onSubmitFormFn = useCallback(
    (formData: SignUpFormData, consentTemplate: string) => {
      setIsLoading(true);
      validate(formData.email)
        .then((emailValid) => {
          if (!emailValid.result) throw new Error(t('errors:email.invalid'));
          return createUserWithEmailAndPassword(
            auth,
            formData.email,
            formData.password,
          );
        })
        .then(({ user }) => sendEmailVerification(user).then(() => user))
        .then((user) =>
          mutate({
            firebaseId: user.uid,
            ...omit(formData, ['password']),
            token: token as string,
            consentTemplate,
          }),
        )
        .catch((error) => {
          if (error instanceof FirebaseError)
            setError(t(`errors:firebase.${error?.code}`));
          else setError(error.message);
        })
        .finally(() => setIsLoading(false));
    },
    [auth, mutate, token, validate],
  );
  */
  const onSubmitFormFn = useCallback(
    async (formData: SignUpFormData, consentTemplate: string) => {
      setIsLoading(true);
      setError('');
      try {
        const emailValidation = await formRef.current?.validateEmail();

        if (emailValidation && !emailValidation.result)
          throw new Error(t('errors:email.invalid'));

        if (auth.currentUser) await auth.signOut();
        const { user } = await createUserWithEmailAndPassword(
          auth,
          formData.email,
          formData.password,
        );
        await sendEmailVerification(user);
        mutate({
          firebaseId: user.uid,
          ...omit(formData, ['password']),
          token: token as string,
          consentTemplate,
        });
      } catch (error) {
        /**
         * TODO List:
         * [] - Delete user on firebase if backend fails to create user
         */
        setIsLoading(false);
        if (error instanceof FirebaseError)
          setError(t(`errors:firebase.${error?.code}`));
        else setError((error as Error).message);
      }
      setIsLoading(false);
    },
    [auth, mutate, token],
  );

  const onDismissMessageError = useCallback(() => {
    setError('');
  }, []);

  return isFetching && checkInvitationLoading ? (
    <Loader />
  ) : (
    errorElement ?? (
      <Layout>
        <div className={stylesSignIn.container}>
          <div className={stylesSignIn.header}>
            <div className={stylesSignIn.left}>
              <LogoHeader />
            </div>
            <div className={stylesSignIn.right}>
              <Button
                label={t('forms:signIn.submit')}
                size="medium"
                variant="outline"
                link={{ to: ROUTES.SIGN_IN, preventScrollReset: false }}
              />
            </div>
          </div>
          <div className={stylesSignIn.content}>
            <div className={stylesSignIn.wrapper}>
              <div className={stylesSignIn.form}>
                <h1 className={stylesSignIn.title}>
                  {t('forms:signUp.title')}
                </h1>
                {error && (
                  <div className={stylesSignIn.box}>
                    <BoxMessage
                      variant="error"
                      onDismiss={onDismissMessageError}
                    >
                      {error}
                    </BoxMessage>
                  </div>
                )}
                <SignUpForm
                  isLoading={isLoading}
                  onSubmitFn={onSubmitFormFn}
                  ref={formRef}
                />
              </div>
            </div>
            <div
              className={classNames(stylesSignIn.wrapper, stylesSignIn.aside)}
            >
              <div className={stylesSignIn.illustration}>
                <SignInIllustration />
              </div>
              <div className={stylesSignIn.caption}>
                <h1>{t('common:welcomePage.title')}</h1>
                <p>{t('common:welcomePage.description')}</p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  );
}
