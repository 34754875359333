import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import * as Tabs from '@radix-ui/react-tabs';
import { IconChevronsDown, IconSearch, IconX } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { ClipLoader } from 'react-spinners';
import { useDebounce } from 'use-hooks';

import { UserContext } from '@/contexts/UserContext';
import useUpdateAccountRole from '@/hooks/account/useUpdateAccountRole';
import { ANALYTICS_PAGE_NAME, useAnalytics } from '@/hooks/utils/useAnalytics';
import useResourceCountByStatusQuery from '@/hooks/workspace/resources/useResourceCountByStatusQuery';
import useSearchManagerResourcesQuery, {
  SEARCH_MANAGER_RESOURCES_KEY,
} from '@/hooks/workspace/resources/useSearchManagerResourcesQuery';
import useUpdateResourcePermission from '@/hooks/workspace/resources/useUpdateResourcePermission';
import { getCssVariable } from '@/services/helpers';
import { RESOURCE_STATUS, WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { TResourceSearchResult } from '@/types/timeline';
import { TUserMember } from '@/types/workspace';

import Button from '@/components/Button';
import Input from '@/components/Input';
import Section from '@/components/Section';

import MemberManageSection from './MemberSection';
import styles from './styles.module.css';
import ModalAddNewTeamMember from '../../ModalAddNewTeamMember';

type TTabsColumns = {
  title: string;
  value: RESOURCE_STATUS;
  count: number | undefined;
  isActive: boolean;
  onCLick: () => void;
};

export default function ModalResourceManage() {
  const [searchValue, setSearchValue] = React.useState<string | undefined>();
  const debouncedValue = useDebounce(searchValue, 300);

  const { trackPage } = useAnalytics();
  const { workspaceId } = useContext(UserContext);

  const [viewHidden, setViewHidden] = useState<boolean>(false);

  useEffect(() => {
    if (trackPage && workspaceId)
      trackPage(ANALYTICS_PAGE_NAME.PEOPLE, workspaceId);
  }, [workspaceId, trackPage]);

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useSearchManagerResourcesQuery({
      query:
        debouncedValue && debouncedValue.length > 2
          ? debouncedValue
          : undefined,
      showhidden: viewHidden,
      enabled: !!workspaceId,
    });

  const resources = useMemo(() => {
    return data?.pages.reduce((acc, page) => {
      return acc.concat(page.results);
    }, [] as TResourceSearchResult[]);
  }, [data]);

  const { mutate: mutateRole } = useUpdateAccountRole();

  const { mutate: mutateInvitationPermission } = useUpdateResourcePermission();

  const onUpdatePermission = useCallback(
    (
      resource: TResourceSearchResult,
      permission: WORKSPACE_MEMBER_PERMISSION,
      workspaceId: string,
    ) => {
      if (!workspaceId) return;

      mutateInvitationPermission({
        workspaceId,
        permission,
        resourceId: resource.id,
      });
    },
    [mutateInvitationPermission],
  );

  const [isAddPersonModalOpen, setIsAddPersonModalOpen] =
    useState<boolean>(false);
  const queryClient = useQueryClient();

  const onAddPersonModalClose = useCallback(
    (newMember?: TUserMember | object, saved?: boolean) => {
      if (saved && newMember)
        queryClient.invalidateQueries([SEARCH_MANAGER_RESOURCES_KEY]);
      setIsAddPersonModalOpen(false);
    },
    [queryClient],
  );

  const { data: resourceCounts } = useResourceCountByStatusQuery({
    query:
      debouncedValue && debouncedValue.length > 2 ? debouncedValue : undefined,
    enabled: !!workspaceId,
  });

  const TABS_COLUMNS: TTabsColumns[] = useMemo(
    () =>
      [
        {
          title: 'Active',
          value: RESOURCE_STATUS.ACTIVE,
          count: resourceCounts?.resourceActiveCount ?? 0,
          isActive: !viewHidden,
          onCLick: () => setViewHidden(false),
        },
        {
          title: 'Hidden',
          value: RESOURCE_STATUS.HIDDEN,
          count: resourceCounts?.resourceHiddenCount ?? 0,
          isActive: viewHidden,
          onCLick: () => setViewHidden(true),
        },
      ] as TTabsColumns[],
    [
      resourceCounts?.resourceActiveCount,
      resourceCounts?.resourceHiddenCount,
      viewHidden,
    ],
  );

  const activeTab: RESOURCE_STATUS = useMemo(
    () => (!viewHidden ? RESOURCE_STATUS.ACTIVE : RESOURCE_STATUS.HIDDEN),
    [viewHidden],
  );

  return (
    <React.Fragment>
      <Section title="Members">
        <Tabs.Root className="TabsRoot" value={activeTab}>
          <Tabs.List
            className={classNames('TabsList', styles.tabsListWrapper)}
            aria-label="Manage your account"
          >
            <div className={styles.tabsTriggerWrapper}>
              {TABS_COLUMNS.map((tab) => (
                <Tabs.Trigger
                  key={`${tab.value}-key`}
                  className="TabsTrigger"
                  value={tab.value}
                  asChild
                >
                  <Button
                    size="medium"
                    variant="ghost"
                    className={tab.isActive ? styles.activeTab : ''}
                    label={
                      <div className={styles.buttonLabelContainer}>
                        <span className={styles.buttonLabel}>{tab.title}</span>
                        <span className={styles.buttonLabelCount}>
                          {tab.count}
                        </span>
                      </div>
                    }
                    onClick={tab.onCLick}
                  />
                </Tabs.Trigger>
              ))}
            </div>
            <div className={styles.searchWrapper}>
              <Input
                className={styles.searchInput}
                LeadingIcon={<IconSearch size={16} color="#b0b3bb" />}
                TrailingIcon={
                  <div className={styles.trailinSearchContainer}>
                    {!!searchValue && (
                      <IconX
                        className={styles.clearSearchIcon}
                        size={16}
                        color={getCssVariable('--icon-subdued')}
                        onClick={() => setSearchValue(undefined)}
                      />
                    )}
                  </div>
                }
                name={'search'}
                value={searchValue ?? ''}
                placeholder="Filter people..."
                onChange={({ target: { value } }) => setSearchValue(value)}
              />
              <Button
                size="medium"
                variant="default"
                label="Add person"
                onClick={() => setIsAddPersonModalOpen(true)}
              />
            </div>
          </Tabs.List>
          {[RESOURCE_STATUS.ACTIVE, RESOURCE_STATUS.HIDDEN].map((val) => (
            <Tabs.Content
              className="TabsContent"
              value={val}
              key={`${val}-content`}
            >
              <MemberManageSection
                workspaceId={workspaceId!}
                resources={resources ?? []}
                onUpdatePermission={(data) => {
                  onUpdatePermission(
                    data.resource,
                    data.permission,
                    workspaceId!,
                  );
                }}
                onUpdateRole={mutateRole}
              />
              {isFetchingNextPage ? (
                <ClipLoader size={16} />
              ) : (
                hasNextPage && (
                  <div
                    tabIndex={0}
                    role="button"
                    onKeyDown={({ key }) =>
                      key === 'Enter' ? fetchNextPage() : null
                    }
                    className={styles.footerAction}
                    onClick={() => fetchNextPage()}
                  >
                    <IconChevronsDown size={16} />
                    <span className={styles.footerActionText}>View more</span>
                  </div>
                )
              )}
            </Tabs.Content>
          ))}
        </Tabs.Root>
      </Section>
      {isAddPersonModalOpen && (
        <ModalAddNewTeamMember
          isOpen={isAddPersonModalOpen}
          onClose={onAddPersonModalClose}
          defaultName={searchValue}
        />
      )}
    </React.Fragment>
  );
}
