import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Button from '@components/Button';
import Input from '@components/Input';
import * as Tooltip from '@radix-ui/react-tooltip';
import { IconTrash } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { diff } from 'deep-diff';
import { validate as EmailValidator } from 'email-validator';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import truncateMiddle from 'truncate-middle';

import { UserContext } from '@/contexts/UserContext';
import { ACCOUNT_QUERY_KEY } from '@/hooks/account/useAccountQuery';
import { ANALYTICS_EVENTS, useAnalytics } from '@/hooks/utils/useAnalytics';
import useDeleteResourceImage from '@/hooks/workspace/resources/useDeleteResourceImage';
import useUpdateResource from '@/hooks/workspace/resources/useUpdateResource';
import useUpdateResourceImage from '@/hooks/workspace/useUpdateResourceImage';
import { STORAGE_BASE_PATH, WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { TAttachmentDataUrls } from '@/types/generic';
import { TUserMember } from '@/types/workspace';

import Modal from '@/components/Modals/Modal';

import styles from './styles.module.css';
import AvatarUploader from '../../AvatarUploader';
import Badge from '../../Badge';
import Chip from '../../Chip';
import SelectDropdown from '../../SelectDropdown';
import Switch from '../../Switch';

type Props = {
  isOpen: boolean;
  onClose: (obj?: object | TUserMember, updated?: boolean) => void;
  onDelete: () => void;
  image?: TAttachmentDataUrls;
  resourceId: string;
  member: TUserMember;
};

export default function ModalEditTeamMember({
  isOpen,
  onClose,
  onDelete,
  image,
  resourceId,
  member,
}: Props) {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TUserMember>({ mode: 'onSubmit' });

  const { mutate, isLoading } = useUpdateResource();
  const { mutate: mutateResourceImage } = useUpdateResourceImage();
  const { account, workspaceId, workspace, modeMultiplier } =
    useContext(UserContext);
  const [attachments, setAttachments] = useState<TAttachmentDataUrls[]>([]);
  const watchFirstName = watch('firstName');

  const watchLastName = watch('lastName');
  const watchCapacity = watch('capacity');
  const watchWorkspaceAccess = watch('workspaceAccess.hasAccess');
  const watchWorkspaceAccessEmail = watch('workspaceAccess.email');

  const queryClient = useQueryClient();

  const userIsDifferentThanResource =
    member?.workspaceAccess?.email !== account?.email;
  const updateAccount = useCallback(() => {
    if (!userIsDifferentThanResource) {
      queryClient.invalidateQueries([ACCOUNT_QUERY_KEY]);
    }
  }, [queryClient, userIsDifferentThanResource]);

  const [workspaceMemberRole, setWorkspaceMemberRole] =
    useState<WORKSPACE_MEMBER_PERMISSION>(
      WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE,
    );

  useEffect(() => {
    setWorkspaceMemberRole(
      member?.workspaceAccess?.permission ||
        WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE,
    );
  }, [member]);
  const { mutate: mutateDeleteResourceImage } = useDeleteResourceImage();
  const onUploadSuccessFn = useCallback(
    (data: TAttachmentDataUrls) => {
      setAttachments([data]);
      mutateResourceImage(
        {
          resourceId,
          name: data.name as string,
          filePath: data?.ref as string,
          url: data.url as string,
        },
        { onSuccess: updateAccount },
      );
      updateAccount();
    },

    [mutateResourceImage, resourceId, updateAccount],
  );

  useEffect(() => {
    setValue('firstName', member?.firstName || '');
    setValue('lastName', member?.lastName || '');
    setValue('capacity', member?.capacity || 0);
    setValue(
      'workspaceAccess.hasAccess',
      member?.workspaceAccess?.hasAccess || false,
    );
    setValue('workspaceAccess.email', member?.workspaceAccess?.email || '');
  }, [member, setValue]);

  const onRemoveFileSuccessFn = useCallback(
    (id: string) => {
      mutateDeleteResourceImage(
        { resourceId, imageId: id },
        {
          onSuccess: () => {
            updateAccount();
            setAttachments([]);
          },
        },
      );
    },
    [mutateDeleteResourceImage, resourceId, updateAccount],
  );

  const canDelete = useMemo(
    () =>
      workspace?.role === 'OWNER' || member?.workspaceAccess?.role !== 'OWNER',
    [member?.workspaceAccess?.role, workspace?.role],
  );

  const onRemoveAccess = useCallback(
    (currentMember: TUserMember) => {
      if (
        confirm(
          `Are you sure you want remove access to ${currentMember.firstName}?`,
        )
      ) {
        // Save it!x
        mutate(
          { ...currentMember, resourceId },
          { onSuccess: () => onClose(currentMember, true) },
        );
      }
    },
    [mutate, onClose, resourceId],
  );
  const { trackEvent } = useAnalytics();

  const onSuccessFn = useCallback(
    (currentMember: TUserMember) => {
      if (
        currentMember.workspaceAccess?.hasAccess &&
        !member.workspaceAccess?.hasAccess
      ) {
        trackEvent(ANALYTICS_EVENTS.PERSON_INVITED, workspaceId as string);
      }
      updateAccount();

      onClose(currentMember, true);
    },
    [
      member.workspaceAccess?.hasAccess,
      onClose,
      trackEvent,
      workspaceId,
      updateAccount,
    ],
  );

  const onSubmitFn = useCallback(
    (currentMember: TUserMember) => {
      if (
        !currentMember.workspaceAccess?.hasAccess &&
        member.workspaceAccess?.hasAccess
      ) {
        // here we need to show modal of remove access and pass currentMember to it
        onRemoveAccess(currentMember);
        return;
      }

      mutate(
        {
          ...currentMember,
          workspaceAccess: {
            ...currentMember.workspaceAccess,
            permission: workspaceMemberRole,
          },
          resourceId,
        },
        { onSuccess: () => onSuccessFn(currentMember) },
      );
    },
    [
      member,
      mutate,
      workspaceMemberRole,
      resourceId,
      onSuccessFn,
      onRemoveAccess,
    ],
  );

  const hasUnsavedChange = diff(
    {
      firstName: member?.firstName,
      lastName: member?.lastName,
      capacity: Number(member?.capacity),
      workspaceAccess: member?.workspaceAccess?.hasAccess ?? false,
      workspaceAccessEmail: member?.workspaceAccess?.email ?? '',
      workspaceAccessPermission: member?.workspaceAccess?.permission ?? '',
    },
    {
      firstName: watchFirstName,
      lastName: watchLastName,
      capacity: Number(watchCapacity),
      workspaceAccess: watchWorkspaceAccess,
      workspaceAccessEmail: watchWorkspaceAccessEmail ?? '',
      workspaceAccessPermission: workspaceMemberRole,
    },
  );

  const shouldBeDisabled =
    !watchFirstName ||
    !watchLastName ||
    Number(watchCapacity) < 0 ||
    (watchWorkspaceAccess && !watchWorkspaceAccessEmail) ||
    !hasUnsavedChange;

  useEffect(() => {
    if (image?.ref) {
      setAttachments([image]);
    }
  }, [image]);

  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    ref?.current?.focus();
  }, [isOpen]);

  const canUpdateRole = useMemo(() => {
    return (
      workspace?.role === 'OWNER' || member?.workspaceAccess?.role !== 'OWNER'
    );
  }, [member?.workspaceAccess?.role, workspace?.role]);

  return (
    <Modal
      isOpen={isOpen}
      modalKey={`modal-edit-team-member-${resourceId}`}
      onClose={onClose}
      title={
        !userIsDifferentThanResource ? (
          <div className={styles.modalHeader}>
            <p>{t('common:modals.editTeamMember.title')}</p>
            <Badge type="neutral" className={styles.shortBadge}>
              {t('common:modals.editTeamMember.you')}
            </Badge>
          </div>
        ) : (
          t('common:modals.editTeamMember.title')
        )
      }
      footer={
        <div className={styles.modalFooter}>
          <div>
            <Button
              label={t('common:modals.editTeamMember.primaryCtaLabel')}
              onClick={handleSubmit(onSubmitFn)}
              size="medium"
              disabled={shouldBeDisabled}
              isLoading={isLoading}
            />
            <Button
              label={t('common:cancel')}
              onClick={onClose}
              size="medium"
              variant="ghost"
            />
          </div>
          {userIsDifferentThanResource && (
            <div>
              <Button
                icon={IconTrash}
                variant="ghost"
                size="medium"
                onClick={onDelete}
                disabled={!canDelete}
              />
            </div>
          )}
        </div>
      }
    >
      <div className={styles.inputWrapper}>
        <AvatarUploader
          onRemoveFileSuccess={onRemoveFileSuccessFn}
          basePath={STORAGE_BASE_PATH.RESOURCES}
          onChange={setAttachments}
          resourceName={watchFirstName || watchLastName}
          onUploadSuccess={onUploadSuccessFn}
          label={t('common:modals.profilePicture')}
          multiple={false}
          data={attachments}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmitFn)}>
        <div className={styles.inputWrapper}>
          <Controller
            rules={{
              required: {
                value: true,
                message: t('forms:required', {
                  field: t('common:modals.firstName'),
                }),
              },
              maxLength: {
                value: 180,
                message: t('forms:maxLength', {
                  field: t('common:modals.firstName'),
                  count: 180,
                }),
              },
            }}
            control={control}
            name="firstName"
            render={({
              field: { value, name, onChange, onBlur },
              fieldState: { error },
            }) => {
              return (
                <Input
                  required
                  ref={ref}
                  onBlur={onBlur}
                  label={t('common:modals.firstName')}
                  value={value}
                  placeholder={'E.g. John'}
                  onChange={onChange}
                  name={name}
                  error={error?.message as string}
                />
              );
            }}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Controller
            rules={{
              required: {
                value: true,
                message: t('forms:required', {
                  field: t('common:modals.lastName'),
                }),
              },
              maxLength: {
                value: 180,
                message: t('forms:maxLength', {
                  field: t('common:modals.lastName'),
                  count: 180,
                }),
              },
            }}
            control={control}
            name="lastName"
            render={({
              field: { value, name, onChange, onBlur },
              fieldState: { error },
            }) => {
              return (
                <Input
                  required
                  onBlur={onBlur}
                  label={t('common:modals.lastName')}
                  value={value}
                  placeholder={'E.g. Doe'}
                  onChange={onChange}
                  name={name}
                  error={error?.message as string}
                />
              );
            }}
          />
        </div>
        <div className={styles.halfWrapper}>
          <Controller
            rules={{
              validate: (value: number) => {
                if (value < 0) {
                  return t('forms:required', {
                    field: t('common:modals.capacity'),
                  }) as string;
                }
                return true;
              },
            }}
            control={control}
            defaultValue={0}
            name="capacity"
            render={({
              field: { value, name, onChange, onBlur },
              fieldState: { error },
            }) => {
              const onChangeFn = (e: React.ChangeEvent<HTMLInputElement>) => {
                if (
                  !isNaN(Number(e.target.value)) &&
                  Number(e.target.value) <= 7 * ((8 / modeMultiplier) as number)
                ) {
                  onChange(Number(e.target.value));
                }
              };
              return (
                <Input
                  required
                  onBlur={onBlur}
                  label={t('common:modals.capacity')}
                  value={value < 0 ? '' : value?.toString()}
                  placeholder={'E.g 5'}
                  onChange={onChangeFn}
                  name={name}
                  error={error?.message as string}
                />
              );
            }}
          />
          <p>{t(`common:${workspace?.mode ?? 'days'}PerWeek`)}</p>
        </div>
        {userIsDifferentThanResource && (
          <div className={styles.inputWrapper}>
            <Controller
              rules={{
                required: false,
              }}
              control={control}
              name="workspaceAccess.hasAccess"
              render={({ field: { value, name, onChange } }) => {
                return (
                  <Switch
                    id={name}
                    label={t('common:modals.accessWorkspace')}
                    checked={value ?? false}
                    onChange={onChange}
                    disabled={!canUpdateRole}
                  />
                );
              }}
            />
          </div>
        )}
        {watch('workspaceAccess.hasAccess') && userIsDifferentThanResource ? (
          <div
            className={classNames(styles.workspaceAccessWrapper, {
              [styles.hasAccess]:
                member?.workspaceAccess?.pending ||
                member?.workspaceAccess?.email,
            })}
          >
            {member?.workspaceAccess?.email ? (
              <div className={styles.emailInvited}>
                <Tooltip.Provider delayDuration={0}>
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <p className={styles.email}>
                        {truncateMiddle(
                          member?.workspaceAccess?.email,
                          14,
                          14,
                          '...',
                        )}
                      </p>
                    </Tooltip.Trigger>
                    <Tooltip.Portal
                      container={document.getElementById(
                        'radix-tooltip-portal',
                      )}
                    >
                      <Tooltip.Content
                        className="TooltipContent"
                        sideOffset={5}
                      >
                        {member?.workspaceAccess?.email}
                        <Tooltip.Arrow className="TooltipArrow" />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>
                </Tooltip.Provider>

                {member?.workspaceAccess?.pending && (
                  <Chip size="small" type="informative">
                    {t('common:invited')}
                  </Chip>
                )}
              </div>
            ) : (
              <>
                <div className={styles.inputWrapper}>
                  <Controller
                    rules={{
                      required: {
                        value: watch('workspaceAccess.hasAccess') ?? false,
                        message: t('forms:signIn.email.required'),
                      },
                      validate: (value) => {
                        if (!EmailValidator(value ?? '')) {
                          return t('forms:signIn.email.invalid') as string;
                        }
                        return true;
                      },
                    }}
                    control={control}
                    name="workspaceAccess.email"
                    render={({ field: { value, name, onChange, onBlur } }) => {
                      return (
                        <Input
                          required
                          placeholder={t('common:placeholderEmail')}
                          value={value ?? ''}
                          onChange={onChange}
                          name={name}
                          onBlur={onBlur}
                          error={
                            errors?.workspaceAccess?.email?.message as string
                          }
                        />
                      );
                    }}
                  />

                  <p className={styles.inputMessage}>
                    {t('common:modals.emailInvitationMessage')}
                  </p>
                </div>
              </>
            )}
            <SelectDropdown
              disabled={!canUpdateRole}
              label={t(
                `common:modals.roles.${
                  workspaceMemberRole ===
                  WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE
                    ? 'editor'
                    : 'viewer'
                }.label`,
              )}
            >
              <SelectDropdown.Item
                label={t('common:modals.roles.editor.label')}
                subLabel={t('common:modals.roles.editor.description')}
                onSelect={() =>
                  setWorkspaceMemberRole(
                    WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE,
                  )
                }
                checked={
                  workspaceMemberRole ===
                  WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE
                }
              />
              <SelectDropdown.Item
                label={t('common:modals.roles.viewer.label')}
                subLabel={t('common:modals.roles.viewer.description')}
                onSelect={() =>
                  setWorkspaceMemberRole(WORKSPACE_MEMBER_PERMISSION.READ_ONLY)
                }
                checked={
                  workspaceMemberRole === WORKSPACE_MEMBER_PERMISSION.READ_ONLY
                }
              />
            </SelectDropdown>
          </div>
        ) : (
          false
        )}
        <input type="submit" hidden />
      </form>
    </Modal>
  );
}
