import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { noop } from 'lodash';

import { useAnalytics } from '@/hooks/utils/useAnalytics';

import ModalUserProfile, {
  ContentPages,
} from '@/components/Modals/ModalUserProfile';
import ModalApi from '@/components/Modals/ModalUserProfile/ModalApiContent';
import ModalBillingInfo from '@/components/Modals/ModalUserProfile/ModalBillingInfo';
import ModalNotificationContent from '@/components/Modals/ModalUserProfile/ModalNotificationContent';
import ModalPlans from '@/components/Modals/ModalUserProfile/ModalPlans';
import ModalProfileContent from '@/components/Modals/ModalUserProfile/ModalProfileContent';
import ModalResourceManage from '@/components/Modals/ModalUserProfile/ModalResourceManage';
import ModalWorkspacePreferencesContent from '@/components/Modals/ModalUserProfile/ModalWorkspacePreferencesContent';
import ModalWorkspaceSettingContent from '@/components/Modals/ModalUserProfile/ModalWorkspaceSettingContent';
import ModalWorkspaceSettingGeneral from '@/components/Modals/ModalUserProfile/ModalWorkspaceSettingGeneral';

const ModalContentComponent: Record<ContentPages, React.FC> = {
  [ContentPages.PROFILE_INFO]: ModalProfileContent,
  [ContentPages.PREFERENCES]: ModalWorkspacePreferencesContent,
  [ContentPages.SETTINGS]: ModalWorkspaceSettingContent,
  [ContentPages.NOTIFICATIONS]: ModalNotificationContent,
  // [ContentPages.FEATURE_FLAGS]: WorkspaceFeatureFlags,
  [ContentPages.RESOURCE_MANAGE]: ModalResourceManage,
  [ContentPages.PLANS]: ModalPlans,
  [ContentPages.GENERAL]: ModalWorkspaceSettingGeneral,
  [ContentPages.API]: ModalApi,
  [ContentPages.BILLING_INFO]: ModalBillingInfo,
};

export type ModalContextValue = {
  isPreferenceOpened: boolean;
  selectedPage?: JSX.Element;
  setSelectedPage: React.Dispatch<
    React.SetStateAction<JSX.Element | undefined>
  >;
  selectedPageId?: ContentPages;
  setSelectedPageId: React.Dispatch<
    React.SetStateAction<ContentPages | undefined>
  >;
  initialPage?: ContentPages;
  openModal: (pageId: ContentPages) => void;
  closeModal: () => void;
  selectPage?: (itemId: string) => void;
};

const ModalPreferenceContext = createContext<ModalContextValue>({
  isPreferenceOpened: false,
  openModal: noop,
  closeModal: noop,
  setSelectedPageId: noop,
  setSelectedPage: noop,
});

export function useProfileModal() {
  const context = useContext(ModalPreferenceContext);
  if (!context) {
    throw new Error(
      'useProfileModal must be used within a ModalPreferenceProvider',
    );
  }
  return context;
}

export function ModalPreferencesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [selectedPage, setSelectedPage] = useState<JSX.Element | undefined>();
  const [selectedPageId, setSelectedPageId] = useState<
    ContentPages | undefined
  >();
  const [isPreferenceOpened, setIsPreferenceOpened] = useState<boolean>(false);

  const openModal = useCallback((pageId: ContentPages) => {
    setSelectedPageId(pageId);
    setIsPreferenceOpened(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsPreferenceOpened(false);
    setSelectedPageId(ContentPages.PROFILE_INFO);
  }, []);

  const selectPage = useCallback((itemId: string) => {
    const Component =
      ModalContentComponent[itemId as ContentPages] ?? ModalProfileContent;
    setSelectedPage(<Component />);
  }, []);

  const { trackPage } = useAnalytics();

  useEffect(() => {
    if (selectedPageId) {
      trackPage(selectedPageId);
      selectPage(selectedPageId);
    }
  }, [selectPage, selectedPageId, trackPage]);

  return (
    <ModalPreferenceContext.Provider
      value={{
        isPreferenceOpened,
        selectedPage,
        setSelectedPage,
        selectedPageId,
        setSelectedPageId,
        openModal,
        closeModal,
        selectPage,
      }}
    >
      {children}
      {isPreferenceOpened && (
        <ModalUserProfile
          onClose={closeModal}
          isOpen={isPreferenceOpened}
          selectedPage={selectedPage as JSX.Element}
          selectedPageId={selectedPageId}
          setSelectedPageId={setSelectedPageId}
        />
      )}
    </ModalPreferenceContext.Provider>
  );
}
